import React from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  displayOnDemand,
  displayMessageService,
  displayQuickOrder,
  displayDirectBooking,
  displayUngradedAssignments,
} from '../../actions';

const MainDashboard = ({
  onDisplayOnDemand,
  customerName,
  onDisplayMessagerService,
  onDisplayQuickOrder,
  onDisplayDirectBooking,
  onDisplayUngradedAssignments,
  displayUngradedAssignments,
}) => {
  return (
    <div className="tv-main-dashboard__panel">
      <div className="tv-main-dashboard__container">
        <div className="tv-main-dashboard__title-container">
          <div className="tv-main-dashboard__title">Hej {customerName}</div>
        </div>
        <div className="tv-main-dashboard__content">
          <CardItem icon="fa-briefcase" title="Språktjänster">
            <LinkItem path="/calendar" title="Kalender och översikt" />
            <LinkItem
              path="/calendar"
              title="Förboka Tolk"
              onClick={onDisplayQuickOrder}
            />
            <LinkItem path="/" title="On-Demand" onClick={onDisplayOnDemand} />
            <LinkItem
              path="/calendar"
              title="TolkDirekt"
              onClick={onDisplayDirectBooking}
            />
            <LinkItem
              path="/"
              title="Meddelandeservice"
              onClick={onDisplayMessagerService}
            />
            {/* <LinkItem path="/frasbank" title="Frasbank" /> */}
          </CardItem>
          <CardItem icon="fa-tasks" title="Ärenden">
            <LinkItem path="/report-quality" title="Avvikelser" />
            <LinkItem
              path={`${
                displayUngradedAssignments === true
                  ? '/my-assignment'
                  : '/calendar'
              }`}
              title="Uppdrag att betygsätta"
              onClick={onDisplayUngradedAssignments}
            />
            <LinkItem path="/notifications" title="Notiser" />
          </CardItem>
          <CardItem icon="fa-cog" title="Administration">
            <a
              href="https://www.transvoice.se/talagenomtolk/story_html5.html"
              target="_blank"
              rel="noopener noreferrer"
              className="tv-main-dashboard__card-item"
            >
              Utbildning - Tala genom tolk
            </a>
            <LinkItem path="/reports-dashboard" title="Statistik" />
            <LinkItem path="/my-assignment" title="Våra uppdrag" />
            <LinkItem path="/releases-page" title="Nyheter" />
            <LinkItem
              title="Hantera användare"
              path={{
                pathname: '/settings',
                state: {
                  from: {
                    pathname: '/',
                    tabIndex: 2,
                  },
                },
              }}
            />
          </CardItem>
        </div>
      </div>
    </div>
  );
};

const CardItem = props => {
  return (
    <div className="tv-main-dashboard__card">
      <div className="tv-main-dashboard__card-title">
        <i class={`fa ${props.icon}`} aria-hidden="true"></i>
        <h4>{props.title}</h4>
      </div>
      <div className="tv-main-dashboard__card-items">{props.children}</div>
    </div>
  );
};

const LinkItem = ({ path, title, onClick = () => {} }) => {
  return (
    <NavLink to={path} onClick={onClick}>
      <div className="tv-main-dashboard__card-item">{title}</div>
    </NavLink>
  );
};

MainDashboard.propTypes = {
  customerName: PropTypes.string,
  onDisplayOnDemand: PropTypes.func,
  onDisplayMessagerService: PropTypes.func,
  onDisplayQuickOrder: PropTypes.func,
  onDisplayDirectBooking: PropTypes.func,
  onDisplayUngradedAssignments: PropTypes.func,
  displayUngradedAssignments: PropTypes.bool,
};

MainDashboard.defaultProps = {
  customerName: '',
  onDisplayOnDemand: () => {},
  onDisplayMessagerService: () => {},
  onDisplayQuickOrder: () => {},
  onDisplayDirectBooking: () => {},
  onDisplayUngradedAssignments: () => {},
  displayUngradedAssignments: false,
};

const mapStateToProps = state => ({
  customerName: state.user.profile.customerName,
  displayUngradedAssignments: state.app.displayUngradedAssignments,
});

const mapDispatchToProps = dispatch => ({
  onDisplayOnDemand: () => {
    dispatch(displayOnDemand(true));
  },
  onDisplayMessagerService: () => {
    dispatch(displayMessageService(true));
  },
  onDisplayQuickOrder: () => {
    dispatch(displayQuickOrder(true));
  },
  onDisplayDirectBooking: () => {
    dispatch(displayDirectBooking(true));
  },
  onDisplayUngradedAssignments: () => {
    dispatch(displayUngradedAssignments(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
