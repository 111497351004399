import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import { v4 as uuidv4 } from 'uuid';
import {
  availableResourceAutoAssign,
  autoAssignCreateRequest,
  clearAutoAssignResources,
  fetchContactPersons,
  fetchServices,
  autoAssignOpeningHours,
} from '../../actions';
import {
  Translate,
  TransvoiceButton,
  Modal,
  DropdownList,
  TransvoiceTimePickerV2,
} from '../../../Share/components';
import {
  isIE,
  generateUniqueId,
  createDefaultContactPerson,
} from '../../../Share/utils';
import {
  currentDate,
  durationHourAndMinutes,
  getNearest5MinutesInterval,
  getXTimefromSpecificDate,
} from '../../../Share/utils/dateUtil';
import AvailableInterpreters from './AvailableInterpreters';
import { ContactPersonEdit } from '..';
import OrderComplete from './OrderComplete';
import './style.scss';
import history from '../../../Share/utils/history';
import Searching from './Searching';
import { TOP_SKILLS } from '../../../Share/constants';
import loadScript from '../../../Share/utils/loadScript';
import { clarity } from 'clarity-js';

const INITIAL_STATE = {
  DateNow: currentDate(),
  startTime: {},
  endDuration: {},
  language: {},
  contactPerson: {},
  contactPersonUponInterpretation: {},
  visibleAvailabilityInterpretorModal: false,
  visibleCompletionModal: false,
  visibleRetryMessage: false,
  durations: [],
  duration: {},
  dateRange: {},
  moveForward: true,
  showLoadingOverlay: false,
  showContactPersonError: false,
  showContactPersonUponInterpertationError: false,
  bookDirectUnavailable: false,
  bookDirectAvailableTime: '',
  showContactPersonModal: false,
  showDurationError: false,
  defaultContactPerson: {},
  showSearchingAnimation: false,
  languageDropdownWidth: '140px',
  requiredCompetence: {
    name: 'Högsta tillgängliga kompetens',
    value: 'Högsta tillgängliga kompetens',
  },
};

const REQUIRED_COMPETENCES = [
  {
    name: 'Högsta tillgängliga kompetens',
    value: 'Högsta tillgängliga kompetens',
  },
  {
    name: 'Auktoriserad rättstolk',
    value: 'Auktoriserad rättstolk',
  },
  {
    name: 'Auktoriserad sjukvårdstolk',
    value: 'Auktoriserad sjukvårdstolk',
  },
  {
    name: 'Auktoriserad tolk',
    value: 'Auktoriserad tolk',
  },
  {
    name: 'Utbildad tolk',
    value: 'Utbildad tolk',
  },
];

class BookNowModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      sessionIdentifier: uuidv4(),
      filteredLanguages: [],
    };
    this.timerRef = createRef();
    this.languageElementRef = createRef();
  }

  componentDidMount() {
    const { contactPersons, onGetContactPersons, bookDirectOpeningHours } =
      this.props;
    if (
      !isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours) &&
      bookDirectOpeningHours.AutoAssignOrderOpeningHours[0].IsActive === false
    ) {
      const { DatetimeOpenFrom, DatetimeOpenTo } =
        bookDirectOpeningHours.AutoAssignOrderOpeningHours[0];

      const openHours =
        DatetimeOpenFrom && DatetimeOpenTo
          ? `${moment(DatetimeOpenFrom).format('HH:mm')} - ${moment(
              DatetimeOpenTo,
            ).format('HH:mm')}`
          : '';

      this.setState({
        bookDirectUnavailable: true,
        bookDirectAvailableTime: openHours,
      });
    }
    if (bookDirectOpeningHours.AutoAssignOrderOpeningHours === null) {
      this.setState({
        bookDirectUnavailable: true,
      });
    }
    if (isEmpty(contactPersons)) {
      onGetContactPersons();
    }
    if (!isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours)) {
      this.setState({
        dateRange: {
          startTime: getNearest5MinutesInterval().add(5, 'minutes'),
          endTime: getNearest5MinutesInterval()
            .add(35, 'minutes')
            .add(30, 'minutes'),
          durationDifference: 30,
          bookDirectCloseHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenTo,
            ),
          ),
          bookDirectOpenHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenFrom,
            ),
          ),
        },
        timeFromNowPossibleToBook:
          bookDirectOpeningHours?.NoOfMinutesFromNowPossibleToBook || 120,
        defaultContactPerson: createDefaultContactPerson(),
      });
    }
    this.resizeObserver.observe(this.languageElementRef.current);
    window.clarity('set', 'My watchlist', 'TolkDirekt');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dateRange: { endTime, startTime, bookDirectCloseHour },
      contactPerson,
      contactPersonUponInterpretation,
      language,
      visibleRetryMessage,
      sessionIdentifier,
      showSearchingAnimation,
    } = this.state;
    const {
      createAutoAssignOrder,
      userProfile,
      bookDirectServiceID,
      fetchResourcesAutoAssign,
      returnedOrder,
      assignOrderError,
      bookDirectOpeningHours,
      availableResourcesAutoAssignPending,
    } = this.props;
    if (
      bookDirectOpeningHours !== prevProps.bookDirectOpeningHours &&
      !isEmpty(bookDirectOpeningHours.AutoAssignOrderOpeningHours)
    ) {
      this.setState({
        dateRange: {
          startTime: getNearest5MinutesInterval().add(5, 'minutes'),
          endTime: getNearest5MinutesInterval()
            .add(35, 'minutes')
            .add(30, 'minutes'),
          durationDifference: 30,
          bookDirectCloseHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenTo,
            ),
          ),
          bookDirectOpenHour: moment(
            moment(
              bookDirectOpeningHours.AutoAssignOrderOpeningHours[0]
                .DatetimeOpenFrom,
            ),
          ),
        },
        defaultContactPerson: createDefaultContactPerson(),
        timeFromNowPossibleToBook:
          bookDirectOpeningHours?.NoOfMinutesFromNowPossibleToBook || 120,
        defaultContactPerson: createDefaultContactPerson(),
      });
    }
    if (
      contactPerson !== prevState.contactPerson &&
      contactPerson.Email === null
    ) {
      this.setState({ showContactPersonError: true, moveForward: false });
    }

    if (bookDirectCloseHour !== prevState.dateRange.bookDirectCloseHour) {
      this.generateDurationList();
    }

    if (
      contactPerson !== prevState.contactPerson &&
      contactPerson.Email !== null
    ) {
      this.setState({ showContactPersonError: false, moveForward: true });
    }
    if (
      contactPersonUponInterpretation !==
        prevState.contactPersonUponInterpretation &&
      contactPersonUponInterpretation.Email === null
    ) {
      this.setState({
        showContactPersonUponInterpertationError: true,
        moveForward: false,
      });
    }
    if (
      contactPersonUponInterpretation !==
        prevState.contactPersonUponInterpretation &&
      contactPersonUponInterpretation.Email !== null
    ) {
      this.setState({
        showContactPersonUponInterpertationError: false,
        moveForward: true,
      });
    }
    if (
      this.props.returnedOrder !== prevProps.returnedOrder &&
      this.props.returnedOrder.IsSuccess === true
    ) {
      this.setState({
        visibleCompletionModal: !this.state.visibleCompletionModal,
        visibleAvailabilityInterpretorModal: false,
        visibleRetryMessage: false,
      });
    }
    if (
      this.props.availableResourcesAutoAssign !==
        prevProps.availableResourcesAutoAssign &&
      this.props.availableResourcesAutoAssign.Errors === null &&
      this.props.availableResourcesAutoAssign
        .CanCreateOrderWithOriginalTimeOrderFromTo === false
    ) {
      this.setState({
        visibleAvailabilityInterpretorModal: true,
      });
    }
    if (
      this.props.availableResourcesAutoAssign !==
        prevProps.availableResourcesAutoAssign &&
      this.props.availableResourcesAutoAssign.Errors === null &&
      this.props.availableResourcesAutoAssign
        .CanCreateOrderWithOriginalTimeOrderFromTo
    ) {
      try {
        createAutoAssignOrder(
          bookDirectServiceID,
          contactPerson,
          contactPersonUponInterpretation,
          language,
          {
            TimeFrom: startTime.format('HH:mm'),
            TimeTo: endTime.format('HH:mm'),
          },
          userProfile,
          sessionIdentifier,
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (
      this.props.bookDirectServiceID !== prevProps.bookDirectServiceID &&
      this.props.bookDirectServiceID === ''
    ) {
      this.setState({ moveForward: false });
    }
    if (
      assignOrderError !== prevProps.assignOrderError &&
      !isEmpty(assignOrderError) &&
      typeof assignOrderError === 'object' &&
      assignOrderError[0].ErrorCode === 1075
    ) {
      fetchResourcesAutoAssign(
        bookDirectServiceID,
        language.value,
        startTime.add(5, 'minutes').format('HH:mm'),
        endTime.format('HH:mm'),
      );
      this.setState({
        visibleRetryMessage: !visibleRetryMessage,
      });
    }
    if (!showSearchingAnimation && availableResourcesAutoAssignPending) {
      this.setState({
        showSearchingAnimation: true,
      });
      this.timerRef.current = Date.now();
    } else if (showSearchingAnimation && !availableResourcesAutoAssignPending) {
      const timeDiff = Date.now() - this.timerRef.current;
      if (timeDiff < 4000) {
        setTimeout(() => {
          this.hideSearchingAnimation();
        }, 4000 - timeDiff);
      } else {
        this.hideSearchingAnimation();
      }
    }
    if (
      this.state.filteredLanguages?.length === 0 &&
      this.props.languages?.length > 0
    ) {
      this.setState({
        filteredLanguages: this.appendTopSkillsToFront(this.props.languages),
      });
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  getValueFromList = (filterText, list) => {
    if (filterText === null || filterText === '' || filterText === undefined) {
      return null;
    }
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  hideSearchingAnimation = () => {
    this.setState({
      showSearchingAnimation: false,
    });
  };

  generateDuration = (startTime, endTime) => {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    // duration in hours
    const hours = parseInt(duration.asHours(), 10);

    // duration in minutes
    const minutes = parseInt(duration.asMinutes(), 10) % 60;

    return {
      name: `${hours === 0 ? '' : `${hours} h `}${
        minutes === 0 ? '' : `${minutes} min`
      }`,
      value: parseInt(duration.asMinutes(), 10),
    };
  };

  generateDurationList = () => {
    const {
      dateRange: { startTime, bookDirectCloseHour },
    } = this.state;
    let i = 0;
    let currentTime = moment(startTime);
    const durationList = [];
    const timeDifference = moment.duration(
      moment(bookDirectCloseHour).diff(moment(currentTime)),
    );

    const diff = parseInt(timeDifference.asHours() * 2, 10);
    while (i < diff) {
      currentTime = currentTime.add(30, 'minute');

      durationList.push(
        this.generateDuration(moment(startTime), moment(currentTime)),
      );
      i += 1;
    }
    this.setState({
      durations: durationList,
    });
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    const { executeGetServices } = this.props;
    const { dateRange, timeFromNowPossibleToBook } = this.state;
    if (name === 'duration') {
      const endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        value.value,
        'minutes',
      );

      const duration = moment.duration(moment(endTime).diff(moment()));
      const hours = parseInt(duration.asHours(), 10);
      const timeFromNowPossibleToBookInHours = moment
        .duration(timeFromNowPossibleToBook, 'minutes')
        .asHours();
      if (hours >= timeFromNowPossibleToBookInHours) {
        this.setState({ moveForward: false, showDurationError: true });
      } else {
        this.setState({ moveForward: true, showDurationError: false });
      }

      dateRange.endTime = endTime;
      dateRange.durationDifference = value.value;
      this.setState({ dateRange });
    }

    if (name === 'language') {
      const params = {
        language: value.value,
        startTime: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
      executeGetServices(params);
    }

    if (name === 'contactPerson') {
      this.setState({ contactPersonUponInterpretation: value });
    }

    this.setState({ [name]: value });
  };

  goBackHandler = () => {
    const { clearAutoAssignResources } = this.props;
    clearAutoAssignResources();
    this.setState({
      visibleCompletionModal: false,
      visibleAvailabilityInterpretorModal: false,
    });
  };

  createNewBookingAfterSuccess = () => {
    const { getAutoAssignOpeningHours } = this.props;
    this.setState({
      ...INITIAL_STATE,
    });
    getAutoAssignOpeningHours({
      DateFrom: moment().format('YYYY-MM-DD'),
      DateTo: moment().format('YYYY-MM-DD'),
    });
  };

  redirectToCalendar = () => {
    history.push('/calendar');
    this.props.onCloseClicked();
    this.setState({
      ...INITIAL_STATE,
    });
  };

  completionModalHandler = () => {
    this.setState({
      visibleAvailabilityInterpretorModal: false,
      visibleCompletionModal: false,
      visibleRetryMessage: false,
      language: {},
      contactPerson: {},
      contactPersonUponInterpretation: {},
      duration: {},
      dateRange: {
        startTime: getNearest5MinutesInterval().add(5, 'minutes'),
      },
    });
  };

  showRetryMessageHandler = () => {
    const { visibleRetryMessage } = this.state;
    this.setState({
      visibleRetryMessage: !visibleRetryMessage,
    });
  };

  handleSessionChange = (e, key, rowNumber) => {
    const { dateRange: stateDateRange, timeFromNowPossibleToBook } = this.state;
    const dateRange = stateDateRange;
    dateRange[key] = e;
    if (key === 'startTime') {
      const { startTime, endTime } = dateRange;

      if (
        moment(
          getXTimefromSpecificDate(
            startTime,
            dateRange.durationDifference,
            'minutes',
          ),
        ) > moment(dateRange.bookDirectCloseHour)
      ) {
        this.setState({
          duration: {},
        });
      }

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );

      const duration = moment.duration(
        moment(dateRange.endTime).diff(moment()),
      );
      const hours = parseInt(duration.asHours(), 10);
      const timeFromNowPossibleToBookInHours = moment
        .duration(timeFromNowPossibleToBook, 'minutes')
        .asHours();
      if (hours >= timeFromNowPossibleToBookInHours) {
        this.setState({ moveForward: false, showDurationError: true });
      } else {
        this.setState({ moveForward: true, showDurationError: false });
      }
    }

    this.setState({
      dateRange,
    });
    this.generateDurationList();
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      dateRange: { endTime, startTime },
      language,
      sessionIdentifier,
    } = this.state;
    const { fetchResourcesAutoAssign, bookDirectServiceID } = this.props;
    try {
      fetchResourcesAutoAssign(
        bookDirectServiceID,
        language.value,
        startTime.format('HH:mm'),
        endTime.format('HH:mm'),
        sessionIdentifier,
      );
    } catch (e) {
      console.log(e);
    }
  };

  createBooking = slot => {
    const { TimeFrom } = slot;

    const {
      dateRange: { durationDifference },
      contactPerson,
      contactPersonUponInterpretation,
      language,
      visibleRetryMessage,
      sessionIdentifier,
    } = this.state;
    const {
      createAutoAssignOrder,
      userProfile,
      bookDirectServiceID,
      fetchResourcesAutoAssign,
    } = this.props;

    const timeFromSplitArray = TimeFrom.split(':');
    const timeTo = moment()
      .set({
        hour: timeFromSplitArray[0],
        minute: timeFromSplitArray[1],
      })
      .add(durationDifference, 'minutes');
    try {
      createAutoAssignOrder(
        bookDirectServiceID,
        contactPerson,
        contactPersonUponInterpretation,
        language,
        {
          TimeFrom,
          TimeTo: timeTo.format('HH:mm'),
        },
        userProfile,
        sessionIdentifier,
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleContactPersonModal = (emailRequired, type = '', identifier) => {
    const { defaultContactPerson } = this.state;
    const { contactPersons } = this.props;
    let editableContactPerson = defaultContactPerson;
    if (identifier && identifier !== '') {
      let matchingContactPersonsList = contactPersons.filter(
        x => x.value === identifier,
      );
      matchingContactPersonsList =
        matchingContactPersonsList.length > 0
          ? matchingContactPersonsList[0]
          : {};

      editableContactPerson = {
        ...editableContactPerson,
        ...matchingContactPersonsList,
        editMode: true,
      };
    }
    this.setState({
      showContactPersonModal: true,
      defaultContactPerson: {
        ...editableContactPerson,
        identifier,
        emailRequired,
        type,
      },
    });
  };

  handleContactPersonModalClose = modalState => {
    const { defaultContactPerson } = this.state;
    if (
      defaultContactPerson.type === 'ContactPersonOrderer' &&
      modalState === 'success'
    ) {
      this.setState({
        showContactPersonError: false,
        moveForward: true,
      });
    }
    if (
      defaultContactPerson.type === 'ContactPersonUponInterpretation' &&
      modalState === 'success'
    ) {
      this.setState({
        showContactPersonUponInterpertationError: false,
        moveForward: true,
      });
    }
    this.setState({
      showContactPersonModal: false,
      defaultContactPerson: createDefaultContactPerson(),
    });
  };

  appendTopSkillsToFront = (languages = []) => {
    const top = languages.filter(l => TOP_SKILLS.includes(l.name));
    return [...top.map(t => ({ ...t, highlight: true })), ...languages];
  };

  handleLanguageFilter = ({ filter }) => {
    const { value } = filter;
    let filtered;
    if (value) {
      filtered = this.props.languages.filter(l =>
        l.name.toLowerCase().includes(value.toLowerCase()),
      );
    } else {
      filtered = this.appendTopSkillsToFront(this.props.languages);
    }
    this.setState({
      ...this.state,
      filteredLanguages: filtered,
    });
  };

  handleWidthChange = entries => {
    this.setState({
      languageDropdownWidth: `${
        this.languageElementRef.current?.offsetWidth ?? 140
      }px`,
    });
  };

  resizeObserver = new ResizeObserver(this.handleWidthChange);

  handleFormError = () => {};
  render() {
    const {
      isVisible,
      onOverlayClick,
      onCloseClicked,
      languages,
      contactPersons,
      customerName,
      availableResourcesAutoAssign,
      returnedOrder,
    } = this.props;
    const {
      DateNow,
      language,
      contactPerson,
      contactPersonUponInterpretation,
      dateRange,
      visibleAvailabilityInterpretorModal,
      visibleCompletionModal,
      visibleRetryMessage,
      durations,
      duration,
      moveForward,
      showContactPersonError,
      showContactPersonUponInterpertationError,
      bookDirectUnavailable,
      bookDirectAvailableTime,
      showContactPersonModal,
      defaultContactPerson,
      showDurationError,
      timeFromNowPossibleToBook,
      showSearchingAnimation,
      filteredLanguages,
      languageDropdownWidth,
      requiredCompetence,
    } = this.state;

    return (
      <>
        {showContactPersonModal && (
          <ContactPersonEdit
            {...defaultContactPerson}
            showModal={showContactPersonModal}
            inOrderProcess
            onHandleModal={this.handleContactPersonModalClose}
          />
        )}
        <Modal
          className="tv-booknow__modal tv-booknow_align-unset"
          onCloseClicked={onCloseClicked}
          //  onOverlayClick={this.cancelModalHandler}
          isVisible={isVisible}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerRender={<></>}
          ieOptionClassName={isIE ? 'ie-fix-height' : 'booknow-holder'}
          hideCloseButton={showSearchingAnimation}
        >
          <div className="tv-modal__holder__custom-header">
            <img src="/images/direct-book-logo.svg" alt="direct-book" />
            <h2>
              {Translate({
                content: 'sideBar.bookNow',
              })}
            </h2>
          </div>
          {showSearchingAnimation && <Searching />}
          {!visibleAvailabilityInterpretorModal &&
            !visibleCompletionModal &&
            !bookDirectUnavailable &&
            !showSearchingAnimation && (
              <div className="tv-booknow__container flex-column">
                <div className="tv-booknow__form quick-order-cont side">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleFormError}
                  >
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div
                          className="form-group"
                          ref={this.languageElementRef}
                        >
                          <label htmlFor="language">
                            {Translate({ content: 'bookNow.language' })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="language"
                            required
                            defaultItem={{ name: 'Välj språk', value: '' }}
                            value={this.getValueFromList(
                              language.value,
                              languages,
                            )}
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            options={filteredLanguages}
                            validators={['required']}
                            placeholder="Välj språk"
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.language',
                                  }),
                                },
                              }),
                            ]}
                            popupHeight={280}
                            filterable
                            onFilterChange={this.handleLanguageFilter}
                            extraPopupSettings={{
                              className: 'popup-class-name',
                              width: languageDropdownWidth || '140px',
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div className="form-group">
                          <label>
                            {Translate({ content: 'bookNow.startTime' })}
                          </label>
                          <TransvoiceTimePickerV2
                            key={`${generateUniqueId()}-BookDireact-TransvoiceTimePickerV2-start`}
                            defaultValue={dateRange.startTime}
                            handleValueChange={e =>
                              this.handleSessionChange(e, 'startTime')
                            }
                            rangeEndTime={dateRange.bookDirectCloseHour}
                            className={
                              dateRange.startTime < moment()
                                ? 'tv-form__control--error--timePicker'
                                : ''
                            }
                          />
                          {(dateRange.startTime < moment() ||
                            showDurationError) && (
                            <div className="tv-col-12">
                              <span className="tv-form__error">
                                Starttiden har redan passerats. Välj en starttid
                                från nu eller senare.
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 tv-booknow_col">
                        <div className="form-group">
                          <label htmlFor="endDuration">
                            {Translate({ content: 'bookNow.executionTime' })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="duration"
                            required
                            defaultItem={{ name: 'Välj längd', value: '' }}
                            value={this.getValueFromList(
                              duration.value,
                              durations,
                            )}
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            options={durations}
                            validators={['required']}
                            placeholder="Välj längd"
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.executionTime',
                                  }),
                                },
                              }),
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label htmlFor="contactPerson">
                            {Translate({ content: 'bookNow.contactPerson' })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="contactPerson"
                            required
                            value={this.getValueFromList(
                              contactPerson.value,
                              contactPersons,
                            )}
                            defaultItem={{
                              name: 'Välj Beställare',
                              value: '',
                            }}
                            className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                              showContactPersonError
                                ? 'tv-form__control--error'
                                : ''
                            }`}
                            options={contactPersons}
                            validators={['required']}
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.contactPerson',
                                  }),
                                },
                              }),
                            ]}
                          />
                          {showContactPersonError && (
                            <span className="tv-form__error text-justify">
                              E-post krävs.
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonError,
                                    'ContactPersonOrderer',
                                    contactPerson.value,
                                  );
                                }}
                                className="contact-person-email-edit"
                              >
                                + Lägg till här
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label htmlFor="requiredCompetence">
                            {Translate({
                              content: 'bookNow.requiredCompetence',
                            })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="requiredCompetence"
                            required
                            value={this.getValueFromList(
                              requiredCompetence.value,
                              REQUIRED_COMPETENCES,
                            )}
                            defaultItem={{
                              name: 'Välj Kravställ kompetens',
                              value: '',
                            }}
                            className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                            options={REQUIRED_COMPETENCES}
                            validators={['required']}
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content: 'bookNow.requiredCompetence',
                                  }),
                                },
                              }),
                            ]}
                          />
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-6 col-xl-6 p-2">
                        <div className="form-group">
                          <label htmlFor="contactPersonUponInterpretation">
                            {Translate({
                              content: 'bookNow.contactPersonUponInterpretor',
                            })}
                          </label>
                          <DropdownList
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            name="contactPersonUponInterpretation"
                            required
                            value={this.getValueFromList(
                              contactPersonUponInterpretation.value,
                              contactPersons,
                            )}
                            defaultItem={{
                              name: 'Välj Tolkanvändare',
                              value: '',
                            }}
                            className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                              showContactPersonUponInterpertationError
                                ? 'tv-form__control--error'
                                : ''
                            }`}
                            options={contactPersons}
                            validators={['required']}
                            errorMessages={[
                              Translate({
                                content: 'validator.isRequired',
                                params: {
                                  name: Translate({
                                    content:
                                      'bookNow.contactPersonUponInterpretor',
                                  }),
                                },
                              }),
                            ]}
                          />
                          {showContactPersonUponInterpertationError && (
                            <span className="tv-form__error text-justify">
                              E-post krävs.
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.handleContactPersonModal(
                                    showContactPersonUponInterpertationError,
                                    'ContactPersonUponInterpretation',
                                    contactPersonUponInterpretation.value,
                                  );
                                }}
                                className="contact-person-email-edit"
                              >
                                + Lägg till här
                              </span>
                            </span>
                          )}
                        </div>
                      </div> */}
                    </div>

                    <div className="d-flex flex-column">
                      <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
                        <TransvoiceButton
                          buttonType="submit"
                          type="secondary"
                          disabled={
                            !moveForward || dateRange.startTime < moment()
                          }
                          text={Translate({
                            content: 'bookNow.submitButton',
                          })}
                        />
                      </div>
                      <div className="tv-booknow__text">
                        TolkDirekt är tjänsten för dig som behöver en tolk på
                        studs eller senare samma dag! Tolkningen sker per
                        telefon och vi matchar ihop dig med rätt tolk inom 10
                        sekunder. Hitta din tolk!
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            )}
          {bookDirectUnavailable && (
            <div className="message-container">
              <div className="icon-holder">
                <img
                  src="/images/direct-booking-unavailable.svg"
                  alt="booking-unavailable"
                />
              </div>
              <h1 className="tv-available-interpreters__title">
                Denna tjänst är tyvärr inte tillgänglig just nu
              </h1>
              {bookDirectAvailableTime && (
                <div className="tv-available-interpreters__message">
                  TolkDirekt är tillgängligt {bookDirectAvailableTime}
                </div>
              )}
              <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
                <TransvoiceButton
                  type="primary"
                  text={Translate({
                    content: 'bookNow.cancelButton',
                  })}
                  onClick={onCloseClicked}
                />
              </div>
            </div>
          )}
          {!showSearchingAnimation &&
            visibleAvailabilityInterpretorModal &&
            !visibleCompletionModal && (
              <AvailableInterpreters
                data={availableResourcesAutoAssign.TimeSlots}
                visibleRetryMessage={visibleRetryMessage}
                onSubmit={this.createBooking}
                onGoBack={this.goBackHandler}
              />
            )}
          {visibleCompletionModal && !visibleAvailabilityInterpretorModal && (
            <OrderComplete
              orderNumber={returnedOrder.ReturnedAutoAssignOrder.OrderNumber}
              dateRange={{
                TimeFrom:
                  returnedOrder.ReturnedAutoAssignOrder.DateTimeOrderFrom,
                TimeTo: returnedOrder.ReturnedAutoAssignOrder.DateTimeOrderTo,
              }}
              language={language}
              customerName={customerName}
              bookedInterpreterName={
                returnedOrder.ReturnedAutoAssignOrder.ResourceFullName
              }
              bookedInterpreterNumber={
                returnedOrder.ReturnedAutoAssignOrder.ResourcePhoneNumber
              }
              onClose={this.createNewBookingAfterSuccess}
              onSubmit={this.redirectToCalendar}
            />
          )}
        </Modal>
      </>
    );
  }
}

BookNowModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
  bookDirectOpeningHours: PropTypes.shape({}),
};
BookNowModal.defaultProps = {
  isVisible: false,
  onCloseClicked: () => {},
  onOverlayClick: () => {},
  bookDirectOpeningHours: {},
};

const mapStateToProps = state => {
  const assignment = state.workAssignment.assignmentQuickOrder;
  const {
    availableResourcesAutoAssign,
    availableResourcesAutoAssignPending,
    assignOrderError,
  } = state.workAssignment;
  const contactPersons = state.user.contactPersons || [];
  const { customerName } = state.user.profile;
  const userProfile = state.user.profile;
  const { bookDirectServiceID } = state.user;
  const { returnedOrder } = state.workAssignment;

  // const newContactPersons = state.workAssignment.contactPersons || [];
  // const profile = state.user.profile || [];
  const skills = state.skill.skills || [];
  const languages = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
  }));
  return {
    assignment,
    skills,
    contactPersons,
    customerName,
    // newContactPersons,
    // profile,
    languages,
    availableResourcesAutoAssign,
    availableResourcesAutoAssignPending,
    userProfile,
    returnedOrder,
    bookDirectServiceID,
    assignOrderError,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchResourcesAutoAssign: (
    ServiceIdentifier,
    SkillIdentifier,
    TimeOrderFrom,
    TimeOrderTo,
    sessionIdentifier,
  ) => {
    dispatch(
      availableResourceAutoAssign(
        ServiceIdentifier,
        SkillIdentifier,
        TimeOrderFrom,
        TimeOrderTo,
        sessionIdentifier,
      ),
    );
  },
  createAutoAssignOrder: (
    ServiceIdentifier,
    contactPerson,
    contactPersonUponInterpretation,
    language,
    dateRange,
    customer,
    sessionIdentifier,
  ) => {
    dispatch(
      autoAssignCreateRequest(
        ServiceIdentifier,
        contactPerson,
        contactPersonUponInterpretation,
        language,
        dateRange,
        customer,
        sessionIdentifier,
      ),
    );
  },
  clearAutoAssignResources: () => {
    dispatch(clearAutoAssignResources());
  },
  onGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  executeGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
  getAutoAssignOpeningHours: dateObj => {
    dispatch(autoAssignOpeningHours(dateObj));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BookNowModal);
