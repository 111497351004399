import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { ChangeDashboardDatePeriod, getReportData } from '../../actions';
import { Translate } from '../../../Share/components';
import ClientOrdersChart from './ClientOrdersChart';
import SingleCalendarDateRangePicker from '../../../Share/components/SingleCalendarDateRangePicker/index';
import PercentageChart from './PercentageChart';
import PercentageBarChart from './PercentageBarChart';
import './styles.scss';
import { getLoginLinkExternalReport } from '../../actions/report';

const propTypes = {
  OnDateRangeChange: PropTypes.func,
  onDashboardDateChange: PropTypes.func,
  onGetReportData: PropTypes.func,
  report: PropTypes.shape({}),
  customerProfile: PropTypes.shape({ customerNumber: PropTypes.number }),
  range: PropTypes.shape({
    from: PropTypes.shape({}),
    to: PropTypes.shape({}),
  }),
  getLoginLink: PropTypes.func,
};

const defaultProps = {
  OnDateRangeChange: () => {},
  onDashboardDateChange: () => {},
  onGetReportData: () => {},
  range: {},
  getLoginLink: () => {},
};

const INITAL_STATES = {
  dateRange: {
    start: moment().subtract(1, 'month').startOf('month').toDate(),
    end: moment().startOf('month').toDate(),
  },
  isDateChanged: false,
  error: {
    startDate: null,
    endDate: null,
  },
};

class KPIDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITAL_STATES,
      dateRange: {
        start: props.range.from,
        end: props.range.to,
      },
    };

    this.fetchReports();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customerProfile.customerNumber !==
      this.props.customerProfile.customerNumber
    ) {
      this.props.onGetReportData(
        this.state.dateRange,
        this.props.customerProfile.customerNumber,
      );
    }
  }

  fetchReports = () => {
    const { onDashboardDateChange, onGetReportData, customerProfile } =
      this.props;
    const { dateRange } = this.state;
    onDashboardDateChange(dateRange);
    if (customerProfile.customerNumber) {
      onGetReportData(dateRange, customerProfile.customerNumber);
    }
  };

  handleDateRangeChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;

    if (!this.state.isDateChanged) {
      this.setState({
        isDateChanged: true,
      });
    }

    this.setState(
      {
        dateRange: {
          start: startDate,
          end: endDate,
        },
      },
      () => {
        if (startDate.getTime() !== endDate.getTime()) {
          this.checkForErrors();
        }
      },
    );
  };

  // eslint-disable-next-line class-methods-use-this
  prepareChartData(report, categoryKey) {
    report.sort((a, b) => b.assignmentCount - a.assignmentCount);

    const sumOfDataCount = report.reduce(
      (prevVal, currVal) => prevVal + currVal.assignmentCount,
      0,
    );

    const sumOfResetCount = report
      .slice(10)
      .reduce((prevVal, currVal) => prevVal + currVal.assignmentCount, 0);

    const constructedChartData = report.slice(0, 10).map(data => ({
      category: data[categoryKey] || '-',
      value: data.assignmentCount / sumOfDataCount,
    }));

    if (report.length >= 10) {
      constructedChartData.push({
        category: 'Övriga',
        value: sumOfResetCount / sumOfDataCount,
      });
    }

    return constructedChartData;
  }

  checkForErrors = () => {
    const { dateRange, error, isDateChanged } = this.state;
    const { start, end } = dateRange;
    let err = false;

    if (!isDateChanged) {
      err = true;
      this.setState({
        error: {
          startDate: true,
          endDate: true,
        },
      });
    } else if (start.getTime() === end.getTime()) {
      err = true;
      this.setState({
        error: {
          ...error,
          endDate: true,
        },
      });
    } else {
      this.setState({
        error: {
          startDate: null,
          endDate: null,
        },
      });
    }

    return err;
  };

  handleFilter = () => {
    if (!this.checkForErrors()) this.fetchReports();
  };

  render() {
    const { report, customerProfile, getLoginLink } = this.props;
    const { dateRange, isDateChanged, error } = this.state;

    const range = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };

    const ordersByCompetence = this.prepareChartData(
      report.Competence || [],
      'competenceLevel',
    );
    const ordersBySkills = this.prepareChartData(
      report.SkillStatistics || [],
      'skill',
    );
    const ordersByInterpretationType = this.prepareChartData(
      report.InterpretationType || [],
      'interpretationType',
    );
    const ordersBySuccessRateStatistics = this.prepareChartData(
      report.SuccessRateStatistics || [],
      'SuccessRateName',
    );
    const ordersByOrderChannel = this.prepareChartData(
      report.OrderChannel || [],
      'orderChannel',
    );

    return (
      <div className="dashboard__report-container">
        <div className="dashboard__filter-holder">
          <SingleCalendarDateRangePicker
            range={range}
            onDateRangeChange={this.handleDateRangeChange}
            isTouched={isDateChanged}
            error={error}
          />
          <button
            title="Report Period"
            className="tv-buttons__element tv-buttons__element--primary"
            onClick={this.handleFilter}
          >
            <Translate content="report.extractReport" />
          </button>
          {(error.startDate || error.endDate) && (
            <div className="error-message">
              Du måste välja två datum för att filtrera
            </div>
          )}
          {/* <a
            className="tv-buttons__element tv-buttons__element--primary"
            href={customerProfile.logInLinkStatisticsPortal}
            target="_blank"
          >
            <button
              title="Report Period"
              className="tv-buttons__element--primary"
            >
              <Translate content="report.visitInterpreter" />
            </button>
          </a> */}

          <button
            title="Report Period"
            className="tv-buttons__element tv-buttons__element--primary"
            onClick={getLoginLink}
          >
            <Translate content="report.visitInterpreter" />
          </button>

          {/* <div className="info-message">
            Statistik visar endast fakturerade uppdrag
          </div> */}
          {report?.Competence?.length > 0 && (
            <div className="total-assignments">
              Antal uppdrag: {report.Competence[0]?.totalOrderCount || 0}
            </div>
          )}
        </div>
        <div className="dashboard__report-holder">
          <div className="dashboard__chart-container">
            <div className="dashboard__single-chart-container">
              <PercentageBarChart
                data={ordersByCompetence}
                title="Kompetensfördelning"
              />
            </div>
            <div className="dashboard__single-chart-container">
              <PercentageChart data={ordersBySkills} title="Språkfördelning" />
            </div>
            <div className="dashboard__single-chart-container custom-topic-padding">
              <ClientOrdersChart
                data={ordersByInterpretationType}
                title="Typ av bokning"
              />
            </div>
            <div className="dashboard__single-chart-container custom-topic-padding">
              <ClientOrdersChart
                data={ordersBySuccessRateStatistics}
                title="Tillsättningsgrad"
              />
            </div>
            <div className="dashboard__single-chart-container custom-topic-padding">
              <ClientOrdersChart
                data={ordersByOrderChannel}
                title="Bokningskanal"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

KPIDashboard.propTypes = propTypes;
KPIDashboard.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  range: state.dashboard.range,
  report: state.report,
  customerProfile: state.user.profile,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onGetReportData: (dateRange, customerNumber) => {
    dispatch(getReportData(dateRange, customerNumber));
  },

  onDashboardDateChange: dateRange => {
    dispatch(ChangeDashboardDatePeriod(dateRange));
  },
  getLoginLink: () => {
    dispatch(getLoginLinkExternalReport());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIDashboard);
