/* eslint-disable import/prefer-default-export */
import { transvoiceLog } from '../../Share/utils';
import * as api from '../api';
import { GET_REPORT_DATA } from './ActionTypes';
import { hiddenLoadingOverlay, showLoadingOverlay } from './app';
import moment from 'moment';

export function getReportData(datePeriod, customerNumber) {
  return (dispatch, getState) => {
    const { start, end } = datePeriod;
    const startDate = moment(start).format('MM-DD-yyyy');
    const endDate = moment(end).format('MM-DD-yyyy');
    const state = getState();
    const { token } = state.authentication;
    dispatch(showLoadingOverlay());
    api
      .GetReportData(startDate, endDate, customerNumber, token)
      .then(response => {
        if (response.data && !response.data.Errors) {
          const report = response.data;
          dispatch({
            type: GET_REPORT_DATA,
            report,
          });
        }
      })
      .catch(err => {
        transvoiceLog(err);
      })
      .finally(() => {
        dispatch(hiddenLoadingOverlay());
      });
  };
}

export function getLoginLinkExternalReport() {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    dispatch(showLoadingOverlay());
    api
      .GetLoginLinkExternalReport(token)
      .then(response => {
        if (response.data && !response.data.Errors) {
          const report = response.data;
          if (report && report.GetLoginLinkExternalReportUrl) {
            // Open the link in a new tab
            window.open(
              report.GetLoginLinkExternalReportUrl,
              '_blank',
              'noopener,noreferrer',
            );
          }
        }
      })
      .catch(err => {
        transvoiceLog(err);
      })
      .finally(() => {
        dispatch(hiddenLoadingOverlay());
      });
  };
}
